import {Input, Text, Button, HStack, Box} from '@chakra-ui/react'
import React from 'react'
import ReferredInfos from '../Modal/ReferredInfos'
import Approve from '../Modal/Approve'
import AgentModal from '../Modal/AgentModal';

const range = (len: any) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};


function GetBox(reff:any, code:any) {
  return <ReferredInfos reff={reff} code={code}/>
}

function GetInput(code: any) {
  if (code.length > 0) {
    return <Text>{code}</Text>
  } else {
    // return <HStack><Input/><Button color="white" background="linear-gradient(to left, #34e89e, #0f3443)" px="8"> Approve </Button></HStack>
    return <Text color="grey">Pending Approval</Text>
  }
}

function getAgent(data:any, getData:any) {
  return <AgentModal getData={getData} data={data} />
}

function status(status: number, obj:any, getData:any) {
  
  if (status) {
    return <Text color="lightgreen">Approved</Text>
  } else return <Approve getData={getData} data={obj}/>
}




export const newPerson = (d:any, mockData:any, getData:any) => {
  
  // console.log(d, mockData[d])
  let obj = mockData[d]
  // const statusChance = Math.random();
  return {
    ID: d + 1,
    name: getAgent(obj, getData),
    email: obj.email,
    code: GetInput(obj.code),
    nationality: obj.nationality,
    referred: GetBox(obj.referrals, obj.code),
    approved: status(obj.approved, obj, getData)
    // ID:
    //   statusChance > 0.66
    //     ? "relationship"
    //     : statusChance > 0.33
    //     ? "complicated"
    //     : "single"
  };
};

export type Person = ReturnType<typeof newPerson>;

export default function makeData(refData:any, getData:any) {

  

  let lens = [refData.length]
  const makeDataLevel = (depth = 0): any => {
    const len = lens[depth];
    return range(len).map(d => {
      return {
        ...newPerson(d, refData, getData),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined
      };
    });
  };

  return makeDataLevel();
}

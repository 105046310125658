import Axios from 'axios'
import { linkUsed } from './link'

export async function getAllRef(setRefData:any) {
    await Axios.get(`${linkUsed()}/allref-oman`).catch((err) => {
        if (err) throw err
    }).then((data:any) => {
        setRefData(data.data)
    })
}

export async function getAllProspect(setRefData:any) {
    await Axios.get(`${linkUsed()}/get-all-oman`).catch((err) => {
        if (err) throw err
    }).then((data:any) => {
        setRefData(data.data)
    })
}

// export async function getOMRef(setRefData:any) {
//     await Axios.get(`${linkUsed()}/allref-oman`).catch((err) => {
//         if (err) throw err
//     }).then((data:any) => {
//         setRefData(data.data)
//     })
// }

// export async function getOMProspect(setRefData:any) {
//     await Axios.get(`${linkUsed()}/get-all-oman`).catch((err) => {
//         if (err) throw err
//     }).then((data:any) => {
//         setRefData(data.data)
//     })
// }
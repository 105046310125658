import { Flex } from "@chakra-ui/react";
import React from "react";
import { animated } from "react-spring";
import { useFadeUp } from "../utils";

// export type CardProps = BoxProps & {
//   onClick?: () => void;
//   color?: string;
// };

const AnimatedFlex = animated(Flex);

const Card: React.FC<any> = ({ onClick, children, ...rest }) => {
  const fade = useFadeUp();
  return (
    <AnimatedFlex style={fade} onClick={onClick} {...rest}>
      {children}
    </AnimatedFlex>
  );
};

Card.defaultProps = {
  width: "auto",
  borderWidth: "0px",
  onClick: () => false,
  flexDirection: "column"
};

export default Card;

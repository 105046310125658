import {Box} from '@chakra-ui/react'
import React from 'react'
import RegisteredInfos from '../Modal/RegisteredInfos'
import Axios from 'axios'
import ProspectModal from '../Modal/ProspectModal';

const range = (len: any) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};


function GetBox(reff:any, phone:string, data:any) {
  return <RegisteredInfos reff={reff} phone={phone} data={data}/>
}

function getProspect(data:any) {
  return <ProspectModal  data={data} />
}






export const newPerson = (d:any, mockData:any) => {
  

  let obj = mockData[d]

  
  return {
    ID: d + 1,
    full_name: getProspect(obj),
    email: obj.email,
    phone: obj.phone,
    appointment: obj.appointment,
    // referral: getReferralInfo(obj.referral),
    nationality: obj.nationality,
    date: obj.date,
    wechat: obj.wechat,
    referral: GetBox(obj.referral, obj.phone, obj)
    // ID:
    //   statusChance > 0.66
    //     ? "relationship"
    //     : statusChance > 0.33
    //     ? "complicated"
    //     : "single"
  };
};

export type Person = ReturnType<typeof newPerson>;

export default function makeData(refData:any) {

  

  let lens = [refData.length]
  const makeDataLevel = (depth = 0): any => {
    const len = lens[depth];
    return range(len).map(d => {
      return {
        ...newPerson(d, refData),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined
      };
    });
  };

  return makeDataLevel();
}

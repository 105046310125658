import React, {useState, useEffect} from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement, 
  Image
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import Axios from 'axios'
import {useHistory} from 'react-router-dom'
import LOGO from '../assets/isola.png'

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = ({LogToAccount, username, password, setUserName, setPassword}:any) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  Axios.defaults.withCredentials = true


  return (
    <Flex
      flexDirection="column"
      width="100wh"
      bg="#1A202C"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image w="7rem" src={LOGO} alt="Logo"/>
        <Heading>Welcome</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form>
            <Stack
              spacing={4}
              p="1rem"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt />}
                  />
                  <Input 
                  color="red !important"
                  type="name" 
                  placeholder="Username"
                  onChange={(e:any) => setUserName(e.target.value)}
                   />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    
                    children={<CFaLock />}
                  />
                  <Input
                                    color="red !important"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e:any) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button color="black" h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText textAlign="right">
                </FormHelperText>
              </FormControl>
              <Button
                borderRadius={0}
                type="submit"
                bg="#2C313D"
                color="white"
                variant="solid"
                width="full"
                onClick={LogToAccount}
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;

import React, {useState, useEffect} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Td,
    Th,
    Thead,
    Table,
    Tbody,
    Tr,
    TableCaption,
    Tfoot
  } from "@chakra-ui/react"
  import Axios from 'axios'
import { linkUsed } from '../utilsAxios/link'
  

export default function ReferredInfos({reff, code}:any) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [referred, setReferred] = useState<any>()
    const [totalProspects, setTotalProspects] = useState(0)
    const [referredLayout, setReferredLayout] = useState<any>(null)

    async function OpenModal(code:any) {
        await Axios.post(`https://vt-api.isolaklcc.com/getreferrals`, {code} ).catch((err) => {
            if (err) throw err;
        }).then((res:any) => {
            console.log(res.data)
            setReferred(res.data)

            onOpen();
        })
    }

    useEffect(() => {
        if (referred) {
            let list = referred.map((each:any, index:any) => {
                return <Tr key={index}>
                    <Td color="#90EE90">{index + 1}</Td>
                    <Td color="#90EE90">{each.full_name}</Td>
                    <Td color="#90EE90">{each.email}</Td>
                    <Td color="#90EE90">{each.phone}</Td>
                    <Td color="#90EE90">{each.date}</Td>
                </Tr>
            })

            setReferredLayout(list)
        }
    }, [referred])


    useEffect(() => {
      
      async function getTotalProspects() {
        await Axios.post(`${linkUsed()}/getTotalProspects`, {code}).catch((err) => {
          if (err) throw err;
        }).then((response:any) => {

          setTotalProspects(response.data)
     
        })
      }

      getTotalProspects()
    }, [])




    return (
      <>
        <Button bg="#90EE90" color="black" onClick={() => OpenModal(code)}>{totalProspects}</Button>
  
        <Modal size="3xl" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Referred</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Table variant="simple">
  <TableCaption>List of Referred</TableCaption>
  <Thead>
    <Tr>
      <Th>ID</Th>
      <Th>Name</Th>
      <Th>Email</Th>
      <Th>Phone</Th>
      <Th>Date Reg.</Th>
    </Tr>
  </Thead>
  <Tbody >
      {referredLayout}
    {/* <Tr>
      <Td>inches</Td>
      <Td>millimetres (mm)</Td>
      <Td isNumeric>25.4</Td>
    </Tr>
    <Tr>
      <Td>feet</Td>
      <Td>centimetres (cm)</Td>
      <Td isNumeric>30.48</Td>
    </Tr>
    <Tr>
      <Td>yards</Td>
      <Td>metres (m)</Td>
      <Td isNumeric>0.91444</Td>
    </Tr> */}
  </Tbody>
  <Tfoot>

  </Tfoot>
</Table>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        </>
    )
  }

import React from 'react'
import {
    Button,
    Text,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Modal,
    useDisclosure,
    IconButton,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Tfoot,
    Td,
    Link,
  } from "@chakra-ui/react";
  import { InfoOutlineIcon, ExternalLinkIcon } from "@chakra-ui/icons";
  import Axios from 'axios'
  
  function AgentModal({ data, getData }:any) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [deleting, setDeleting] = React.useState(false)


    function Close() {
        setDeleting(false)
        onClose()
    }


    async function DeleteAgent() {
        // await Axios.post(`https://vt-api.isolaklcc.com/getreferrals`, {code} ).catch((err) => {
  
                await Axios.post(`https://vt-api.isolaklcc.com/delete-agent`, {email:data.email} ).catch((err:any) => {
                  // await Axios.post(`http://localhost:3000/delete-agent`, {email:data.email, code: data.code} ).catch((err:any) => {

              if (err) throw err;
          }).then((res:any) => {
            getData();
              Close();
              console.log(res.data)
              // setReferred(res.data)
          })
      }


    return (
      <>
        <Button bg="#90EE90" color="black" onClick={onOpen}>{data.name}</Button>
        <Modal isOpen={isOpen} onClose={Close}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{data.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textAlign="center" colSpan={2}>
                      Agent's Info
                    </Th>
                  </Tr>
                </Thead>

                {deleting ? <Tbody>
                   Confirm Delete?
                </Tbody> : <Tbody>
                  <Td>
                    <Tr height="7" fontWeight="bold">
                      Full Name
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Email
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Phone
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Link
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Country
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Referred
                    </Tr>
                  </Td>
                  <Td>
                    <Tr height="7">{data.name}</Tr>
                    <Tr height="7">{data.email}</Tr>
                    <Tr height="7">{data.phone}</Tr>
                    <Tr height="7">
                      <Link
                        href={`http://virtual-tour.isolaklcc.com/register-prospect/${data.code}`}
                        isExternal>
                        {data.code} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Tr>
                    <Tr height="7">{data.nationality}</Tr>
                    <Tr height="7">{data.referrals}</Tr>
                  </Td>
                </Tbody>}

              </Table>
            </ModalBody>
  
            <ModalFooter>{deleting ? <Button w="100%" bg="#E01722" onClick={DeleteAgent}>CONFIRM DELETE</Button> : <Button w="100%" bg="#E01722" onClick={() => setDeleting(true)}>DELETE AGENT</Button>}</ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  {
    /* <div className="loading-bg"></div>
            <div className="loading-cont">
              <div className="info-cont">
                <div className="cancel-cont">
                  <Text className="cancel" onClick={(e) => setDisInfo(false)}>
                    ✖
                  </Text>
                </div>
                <h5>Agent's Info</h5>
                <Text>
                  Full Name: <b>{user.full_name}</b>
                </Text>
                <Text>
                  Agency: <b>{user.agency}</b>
                </Text>
                <Text>
                  Email: <b>{user.email}</b>
                </Text>
                <Text>
                  Link:
                  <a href={link} target="_blank">
                    {user.linkHtm}
                  </a>
                </Text>
                <Text>
                  Password: <b>{user.password}</b>
                </Text>
              </div>
            </div> */
  }
  export default AgentModal;
  
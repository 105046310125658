import { Flex, IconButton } from "@chakra-ui/react";
import styled from "@emotion/styled";
import * as React from "react";
import {
  color,
  ColorProps,
  justifyContent,
  JustifyContentProps,
  space,
  SpaceProps
} from "styled-system";
// import {LastPage, FirstPage, ChevronRight, ChevronLeft} from '@material-ui/icons'


export const StyledTable = styled.div<SpaceProps>`
  ${space};
  background: #1A202C;
  flex: 1;
  width: 100%;
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  border-radius: 4px;
  flex-direction: column;
  box-sizing: border-box;
`;

export const TableHead = styled.div<SpaceProps>`
  ${space};
  display: flex;
  flex-direction: row;
`;

export const TableCell = styled<any>("div")`
  ${space};
  ${color};
  ${justifyContent};
  flex: 1;
  background: #1A202C;
  display: flex;
  min-width: 150px;
  align-items: center;
  border-bottom-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableRow = styled(Flex)`
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.01);
  }
`;

type TableIconButtonProps = SpaceProps & {
  icon: any;
  onClick: any;
  // isDisabled: boolean;
};

export const TableIconButton: React.FC<any> = ({
  icon,
  onClick,
  isDisabled,
  children,
  ...rest
}) => {
  return (
    <IconButton
      size="sm"
      {...rest}
      icon={icon}
      borderWidth={1}
      onClick={onClick}
      isDisabled={isDisabled}
      aria-label="Table Icon button"
    >
      {children}
    </IconButton>
  );
};

TableIconButton.defaultProps = {
  // variantColor: "gray"
};

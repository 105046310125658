import React from 'react'
import {HStack, Button, Image, Stack, Text, Flex} from '@chakra-ui/react'
import Logo from '../assets/isola.png'
import {Link} from 'react-router-dom'
import appInfo from '../../package.json'
import { ColorModeSwitcher } from '../ColorModeSwitcher'

export default function Header({loginStatus, setLoginStatus}:any) {
    return (
        <HStack color="white" h="5rem" bg="#3d4962" justifyContent="space-between">
            <Flex justifyContent="center" alignItems="center">
            <Image pl="10" w="6rem" src={Logo} alt="logo"/>
            <Text pl="2" color="darkgrey" opacity="0.6">{appInfo.version}</Text>
            </Flex>
            
                <Stack spacing={10} px={20} direction="row">
                    <ColorModeSwitcher/>
                {loginStatus ? <Link to="/analytics"><Flex _hover={{textDecoration:"underline"}} justifyContent="center" alignItems="center" h="100%">Agents</Flex></Link> : null}
                    {loginStatus ? <Link to="/registered"><Flex _hover={{textDecoration:"underline"}}  justifyContent="center" alignItems="center" h="100%">Prospects</Flex></Link> : null}
                    {loginStatus ? <Button bg="#2C313D"  color="white" onClick={setLoginStatus}>Logout</Button> : null}
                </Stack>
        </HStack>
    )
}

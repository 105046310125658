import { Flex } from "@chakra-ui/react";
// import { FlexProps } from "@chakra-ui/dist/Flex";
import * as React from "react";

// type CardHeaderProps = FlexProps;

const CardHeader: React.FC<any> = ({ children, ...rest }) => {
  return (
    <Flex {...rest}>
      {children}
    </Flex>
  );
};

export default CardHeader;

CardHeader.defaultProps = {
  p: 0,
  roundedTopLeft: 0,
  roundedTopRight: 0,
  flexDirection: "column",
};
